// Backend
import React from 'react';
import axios from 'axios';

// Frontend
import Modal from 'react-modal';
import { Grid, Cell } from 'react-foundation';
import QuestionCategoryContent from '../../components/QuestionCategoryContent';
import { XCircle } from 'react-bootstrap-icons';

// Functionality
import { useParams, useLocation, Link } from 'react-router-dom';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  ProgressBar,
  ErrorRender,
  setToken,
  filteredCategories,
} from '../../constants/SharedComponents';
import useCurrentUser from '../../hooks/useCurrentUser';

// Configurations
Modal.setAppElement('#root');

export function ResourcesShow() {
  // Params
  const { categoryId } = useParams();

  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();
  const token = setToken(currentUser);
  const headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };

  // Objects
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [currentModal, setCurrentModal] = React.useState(null);

  // Functions
  function openModal(event) {
    event.preventDefault();
    setCurrentModal(event.target.getAttribute('data-modal-id'));
  }

  function afterOpenModal() {
    console.log('Modal open');
  }

  function closeModal() {
    setCurrentModal(null);
  }

  function retryFetch() {
    setLoading(true);
    setError(false);
    setData(null);
    getData();
  }

  const getData = async () => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionsCategoriesIndex');
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.categories;
      let allCategories = filteredCategories(responseData, 1, false);
      setData(allCategories.find((obj) => obj.id === categoryId));
      console.dir(allCategories);
      setError(false);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  // Data
  const categoryContent = function (categoryId) {
    let content = '';
    let modalContent = (
      <Modal
        isOpen={currentModal === `question-category-content--${categoryId}`}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel={categoryId}
        shouldCloseOnOverlayClick={true}
      >
        <div className="text-right">
          <button
            onClick={closeModal}
            className="category-form-modal__close-button"
            id={`page-function-button--modal-close-${categoryId}`}
          >
            <XCircle size={48} />
          </button>
        </div>

        <QuestionCategoryContent categoryId={categoryId} />
      </Modal>
    );

    switch (categoryId) {
      case 'ee048b5c-97b2-4f1e-bf90-0da6169729f8':
        content = (
          <div className="page-content">
            <div className="page-content__header">
              <Link
                id="page-navigation-button--go-back"
                className="page-content__button"
                to="/resources"
              >
                Back{' '}
              </Link>
            </div>
            <h1 className="page-content__title">Control</h1>
            <p>
              <button
                onClick={openModal}
                data-modal-id={`question-category-content--${categoryId}`}
                id={`page-function-button--view-${categoryId}`}
              >
                Click here to remember what Control refers to.
              </button>
            </p>
            <div className="page-content__styles">
              <h2 className="page-content__subtitle page-content__subtitle--section">
                TOO MUCH AUTONOMY
              </h2>
              <p>
                Think about the level of control of your team member. Is it too much?
                <br />
                At some point, too much responsibility can be a burden, if it exceeds a person's
                levels of capability and/or capacity.
                <br />
                During the conversation, if this topic emerges, you may take the time to reflect
                together about what they are feeling less comfortable with and how you can support
                them (e.g., training, shadowing, joint decision-making).
              </p>
              <h2 className="page-content__subtitle page-content__subtitle--section">
                LACK OF CONTROL
              </h2>
              <p>
                Think about the level of control of your team member. Is it too low?
                <br />
                While having too much responsibility can be a risk, lack of autonomy is also a major
                source of stress.
                <br />
                During the conversation, if this topic emerges, you may think together about the
                areas where it is realistic to grant more autonomy to your team member (e.g.,
                working scheduling, some decisions, or the way they carry out heir work).
                <br />
                But before granting more autonomy, you should ensure that they have the
                capabilities, knowledge and skills for doing the job right. So, take time to think
                about the capabilities and skills of your team member: are they skilled enough for
                what they will be required? Do they need more training or a prior experience?
                <br />
                Be aware that such individual arrangement may be perceived as unfair by other team
                members who are not granted the same autonomy.
              </p>
              <h2 className="page-content__subtitle page-content__subtitle--section">
                LOW INFLUENCE
              </h2>
              <p>
                Think about your team functioning. Do your team members have enough opportunity to
                express their views on work-related topics?
                <br />
                Lack of opportunity of being heard regarding work-related decisions can be a source
                of stress for employees. Sometimes, unwanted consequences of a decision for team
                members can be avoided by hearing their views.
                <br />
                During the conversation, if this topic emerges, you may think about creating time
                and space for team consultation to hear and integrate their views on relevant topics
                that directly affect them.
              </p>
            </div>
          </div>
        );
        break;
      case 'bb844672-2318-4a9f-b9ac-354f535c7dea':
        content = (
          <div className="page-content">
            <div className="page-content__header">
              <Link
                id="page-navigation-button--go-back"
                className="page-content__button"
                to="/resources"
              >
                Back{' '}
              </Link>
            </div>
            <h1 className="page-content__title">Management Support</h1>
            <p>
              <button
                onClick={openModal}
                data-modal-id={`question-category-content--${categoryId}`}
                id={`page-function-button--view-${categoryId}`}
              >
                Click here to remember what Management Support refers to.
              </button>
            </p>
            <div className="page-content__styles">
              <p>
                Management support is a key part of health work. Although feedback on the support
                you give may be sensitive, this meeting should be taken as an opportunity for upward
                constructive feedback and to know how the support you are offering to your team
                member can be enhanced or improved. It can bring a challenging discussion between
                you and your team member. Because the discussion may not be easy, you should be open
                and as factual as possible.
              </p>
              <p>
                You may ask your team member whether there are specific aspects for which they would
                like specific support. You may ask your team member what they would need to feel
                better supported.
              </p>
              <p>
                Does your team member feel you provide regular feedback? Do they feel you provide
                constructive feedback that will help them to improve next time? Do they know if they
                are doing well?
              </p>
              <p>
                Building an open dialogue is an important part of manager support. Does your team
                member feel that they have enough time to talk to you when needed? Do they feel that
                they can openly come to you if needed? If not, why is that?
                <br />
                Are there aspects of your interaction with your team member that they do not feel
                comfortable with? What are the impacts of these behaviours for them?
              </p>
              <p>&nbsp;</p>
            </div>
          </div>
        );
        break;
      case 'bae25492-5633-48ec-b04f-69f2bc9bc43b':
        content = (
          <div className="page-content">
            <div className="page-content__header">
              <Link
                id="page-navigation-button--go-back"
                className="page-content__button"
                to="/resources"
              >
                Back{' '}
              </Link>
            </div>
            <h1 className="page-content__title">Peer Support</h1>
            <p>
              <button
                onClick={openModal}
                data-modal-id={`question-category-content--${categoryId}`}
                id={`page-function-button--view-${categoryId}`}
              >
                Click here to remember what Peer Support refers to.
              </button>
            </p>
            <div className="page-content__styles">
              <h2 className="page-content__subtitle page-content__subtitle--section">
                TEAM STRUCTURE
              </h2>
              <p>
                Think about the structure or configuration of your team. Has your team a shared
                objective? Are your team members working independently or are they co-dependent?
                <br />
                The way the team is structured influences team member interactions which in turn may
                influence the support offered by colleagues. Team members working independently may
                have less peer interaction and it may be a challenge to foster team cohesiveness;
                team members working interdependently have more team interaction but it may bring
                more conflicts, impeding team cohesiveness. As well, the existence of a shared
                objective can encourage interactions between team members but it does not
                necessarily lead to positive peer support.
                <br />
                It is important to know what influences your team interactions. Take time to reflect
                on : degree of autonomy of your team, institutional context, task structure (e.g.,
                lone working) or the difference of team members (e.g., different professions,
                expertise and skills).
              </p>
              <h2 className="page-content__subtitle page-content__subtitle--section">
                REMOTE WORKING
              </h2>
              <p>
                Think about the new work configuration. Are some of your team members working
                remotely? How might this be affected the team member you are preparing to speak
                with. Are they feeling socially isolated? Is there enough face-to-face time?
                <br />
                Remote working can decrease the quality of social relationships at work. While some
                team members appreciate the flexibility allowed by such working practices, others
                may struggle because they are lacking the support and team cohesion they found in
                face-to-face interactions. Moreover, face-to-face time and interactions are
                particularly critical for the familiarisation phase of new recruits, in which
                informal time and chats can help to integrate to the organisational culture.
                <br />
                During the conversation, if this topic emerges, you could discuss ideas about how to
                structure team interactions that might alleviate the feeling of social isolation.
                You may also think with the wider team about specific arrangements when a newcomer
                is joining (e.g., mentoring or a day on site) for facilitating the integration and
                the feeling of belongness.
              </p>
              <h2 className="page-content__subtitle page-content__subtitle--section">
                TEAM ATMOSPHERE
              </h2>
              <p>
                Think about the climate within the team. Do your team members trust each other? Do
                they respect each other? Is there competition between members?
                <br />
                An atmosphere of competition can hamper the adoption of helping behaviours or
                support as team members are primarily focused on their individual achievements.
                During the conversation, if this topic emerges, you might like to consider what
                aspects of the team climate are currently challenging and what sort of support might
                be most helpful. You might like to consider a team workshop for designing and
                implementing systems to foster respect and mutual help.
              </p>
            </div>
          </div>
        );
        break;
      case '9608c2cc-6822-4a22-9ebf-4e5d7cab50f3':
        content = (
          <div className="page-content">
            <div className="page-content__header">
              <Link
                id="page-navigation-button--go-back"
                className="page-content__button"
                to="/resources"
              >
                Back{' '}
              </Link>
            </div>
            <h1 className="page-content__title">Role</h1>
            <p>
              <button
                onClick={openModal}
                data-modal-id={`question-category-content--${categoryId}`}
                id={`page-function-button--view-${categoryId}`}
              >
                Click here to remember what Role refers to.
              </button>
            </p>
            <div className="page-content__styles">
              <h2 className="page-content__subtitle page-content__subtitle--section">
                ROLE CONFLICT
              </h2>
              <p>
                Think about potential clashes in the role of your team member. Is the remit of your
                team member too broad? Do they sometimes have to deal with conflicting demands?
                <br />
                Clashes in role may be a sign of having multiple roles to complete and of job
                complexity. This is most likely when people have very broad responsibilities.
                <br />
                During the conversation, if this topic emerges, you may have a look together to the
                job description and what they are being asked. If the job description does not
                fairly describe their breadth of work, you may discuss together what would be
                helpful for them (e.g., to relieve the burden of responsibilities or to update the
                job description along with the benefits). You may also give clarity to your team
                member regarding conflicting demands and help them in prioritising the demands.
              </p>
              <h2 className="page-content__subtitle page-content__subtitle--section">
                ROLE AMBIGUITY
              </h2>
              <p>
                Think about the clarity around how to do the job. Is there a lack of formal
                documentation around the job? Are organisational processes and procedures clear? Is
                there enough guidance regarding how regulations or policies apply within the role
                and how team members should behave to be compliant with them? Are there any grey
                areas?
                <br />
                During the conversation, if this topic emerges, you may list with your team member
                the topics for which organisational processes and procedures are unclear or
                difficult to put into practice. You may consider reporting them to the most
                appropriate persons in your organisation to get more information and clarity.
              </p>
              <h2 className="page-content__subtitle page-content__subtitle--section">NEWCOMERS</h2>
              <p>
                Think about your team member. Are they new in the role?
                <br />
                The adaptation phase is really important when new recruits are joining the team.
                They have to learn how things are done in the team and the wider organisation, and
                who they can go to if they have a problem. Therefore, they need more guidance than
                other team members.
                <br />
                If your team member is new, you should consider if they have enough information
                about the role and how to do it (e.g., procedures, policies), about their priorities
                and objectives; and if they have been introduced to key persons or teams for helping
                them doing the job internally or externally.
              </p>
            </div>
          </div>
        );
        break;
      case '8d0b0089-2cd2-4c4f-97ff-588710cba91d':
        content = (
          <div className="page-content">
            <div className="page-content__header">
              <Link
                id="page-navigation-button--go-back"
                className="page-content__button"
                to="/resources"
              >
                Back{' '}
              </Link>
            </div>
            <h1 className="page-content__title">Demands</h1>
            <p>
              <button
                onClick={openModal}
                data-modal-id={`question-category-content--${categoryId}`}
                id={`page-function-button--view-${categoryId}`}
              >
                Click here to remember what Demands refers to.
              </button>
            </p>
            <div className="page-content__styles">
              <h2 className="page-content__subtitle page-content__subtitle--section">
                HIGH WORKLOAD
              </h2>
              <p>
                Think about your team member workload. Is it a realistic amount of work? Can your
                team member achieve what they are asked during the hours of work? Does your team
                member have to work extra time to cover the job duties?
                <br />
                During the conversation, if this topic emerges as an issue, you may take the time to
                reflect together about what they are being asked and the scale of their
                responsibility. Then, think about what would be realistically manageable and what
                could potentially be adjusted to manage it.
              </p>
              <h2 className="page-content__subtitle page-content__subtitle--section">
                WORKLOAD PEAKS
              </h2>
              <p>
                Think about the team member workload over the year. Are there predictably busier
                periods during the year time that are particularly challenging for your team members
                (e.g., end of the accounting year)? What can you offer to your team to better
                control the risks (e.g., more process structure, getting information in advance)?
                <br />
                During the conversation, if this topic emerges, you could develop ideas together to
                better balance the workload.
              </p>
              <h2 className="page-content__subtitle page-content__subtitle--section">
                COMPETING DEMANDS
              </h2>
              <p>
                Think about potential clashes between demands for your team member. Does your team
                member sometimes have to deal with competing or incompatible demands?
                <br />
                During the conversation, if this topic emerges, you could explore these competing
                demands and/or help them in prioritising them. In the longer term, you may try to
                resolve these clashes by reviewing role responsibilities or work processes.
              </p>
              <h2 className="page-content__subtitle page-content__subtitle--section">
                WORK-TO-HOME CONFLICT
              </h2>
              <p>
                Think about how your team member's work potentially interferes with their home life.
                <br />
                During the conversation, if this topic emerges, you may consider a flexible working
                arrangement. However, be aware that such individual arrangement may be perceived as
                unfair by other team members who don't have the same opportunity. Alternatively,
                discuss the team culture around working out of hours (e.g., out-of-hours emails or
                teams chats) and consider whether this is appropriate for your context.
              </p>
              <h2 className="page-content__subtitle page-content__subtitle--section">
                WORK ENVIRONMENT
              </h2>
              <p>
                Think about your team work environment (noise, temperature, vibration, exposure to
                challenging physical working conditions). Are there any physical consequences for
                your team members?
                <br />
                During the conversation, if this topic emerges, you may talk to your team member
                about whether they feel these risks are well controlled or managed. You may also
                wish to consider the consequences of these risks for health and safety
              </p>
              <h2 className="page-content__subtitle page-content__subtitle--section">
                EQUIPMENT AND TOOLS
              </h2>
              <p>
                Think about the technical provisions of the work environment (systems and
                technologies, protective equipment, machinery). Are they fit for purpose? Do they
                help the team member to be more effective or do they cause additional challenges? Is
                there available support when technical provisions are not functioning?
                <br />
                If this topic gives rise to concern, you may consider reporting these concerns to
                the most appropriate persons in your organisation.
              </p>
            </div>
          </div>
        );
        break;
      case '7185abba-d609-4071-b6b9-6e199c475e38':
        content = (
          <>
            <div className="page-content">
              <div className="page-content__header">
                <a
                  id="page-navigation-button--go-back"
                  className="page-content__button"
                  href="/resources"
                >
                  Back{' '}
                </a>
              </div>
              <h1 className="page-content__title">Relationships</h1>
              <p>
                <button
                  onClick={openModal}
                  data-modal-id={`question-category-content--${categoryId}`}
                  id={`page-function-button--view-${categoryId}`}
                >
                  Click here to remember what Relationships refers to.
                </button>
              </p>
              <div className="page-content__styles">
                <p>
                  You should take this moment to ask your team member if they have anything to share
                  with you about their experiences of difficult behaviours in the workplace,
                  particularly any experience of bullying or harassment. If such a situation is
                  disclosed during the conversation, please refer to your organisational policies to
                  know what to do next.
                </p>
                <p>
                  Even if some experiences reported are beyond the scope of what you can directly
                  influence, you may still be able to help alleviate the impacts for your team
                  member by offering support and guidance in line with your organisational policies.
                </p>
                <h2 className="page-content__subtitle page-content__subtitle--section">
                  STRAIN OR CONFLICT WITHIN TEAM
                </h2>
                <p>
                  Think about the relationships within your team. Are work relationships strained
                  within the team or outside of the team with other services or areas?
                  <br />
                  Some conflicts creating strain between teams or team members can emerge around
                  tasks (e.g., different visions on how to do the job, delays in one team hampering
                  work of the other team). Sometimes conflicts that seem to be interpersonal
                  actually originate from the work context (e.g., team interdependence, grey areas
                  around the division of workload or responsibilities, lack of communication). If
                  this is the case, you may think of team building session oriented on specific
                  tasks that are thought to create friction.
                </p>
                <h2 className="page-content__subtitle page-content__subtitle--section">
                  TEAM ATMOSPHERE
                </h2>
                <p>
                  Think about the climate within the team. Is there a cheerful working atmosphere,
                  or is it unpleasant? Is there competition between team members?
                  <br />
                  Inappropriate behaviours in the workplace (bullying, criticism, exclusionary
                  behaviours) can sometimes be fostered by an atmosphere of competition.
                  <br />
                  During the conversation, if this topic emerges, you should ask your team member
                  how the negative working atmosphere manifests and how it is affecting them and
                  their daily work. Then, you can discuss together effective ways to minimise the
                  causes of any interpersonal difficulties, or perhaps the impacts of this working
                  atmosphere.
                </p>
                <h2 className="page-content__subtitle page-content__subtitle--section">
                  LACK OF PROCEDURES
                </h2>
                <p>
                  Think about the systems or procedures for dealing with unacceptable behaviours in
                  the workplace. Are there any? Do your team members know how to report unacceptable
                  workplace behaviours? Do they know how to report them if perpetrated by management
                  levels?
                  <br />
                  Inappropriate behaviours are more likely to emerge if they are seen as accepted.
                  On the contrary, if clear procedures are in place to report and deal with these
                  behaviours, it gives signal to employees that they are not tolerated and
                  sanctioned. If such systems or procedures are not available in your workplace, you
                  may consider informing HR to develop or enhance organisational policies.
                </p>
              </div>
            </div>
          </>
        );
        break;
      case '4af58c57-cbe4-499e-99ec-fb33c45c1b98':
        content = (
          <>
            <div className="page-content">
              <div className="page-content__header">
                <a
                  id="page-navigation-button--go-back"
                  className="page-content__button"
                  href="/resources"
                >
                  Back{' '}
                </a>
              </div>
              <h1 className="page-content__title">Change</h1>
              <p>
                <button
                  onClick={openModal}
                  data-modal-id={`question-category-content--${categoryId}`}
                  id={`page-function-button--view-${categoryId}`}
                >
                  Click here to remember what Change refers to.
                </button>
              </p>
              <div className="page-content__styles">
                <p>
                  The design and management of organisational change may be beyond the scope of what
                  you can influence. However, it may still be helpful to report challenges
                  identified here to higher levels within your organisation.
                </p>
                <h2 className="page-content__subtitle page-content__subtitle--section">
                  FREQUENT CHANGES
                </h2>
                <p>
                  Think about the frequency of changes. Has your team been exposed to multiple
                  changes in a short period of time?
                  <br />
                  Going through multiple changes may create disruption in your team functioning.
                  Your team members may have lost clarity about the definition of their roles and
                  objectives, about what they should do and how they should do the job.
                  <br />
                  During the conversation, if this topic emerges, you may try to gather information
                  to clarify current knowledge about the change for your team and offer guidance.
                  This could include timelines and current understanding of the next steps in the
                  change process.
                </p>
                <h2 className="page-content__subtitle page-content__subtitle--section">
                  POOR COMMUNICATION
                </h2>
                <p>
                  At the organisational level: Think about how changes are usually communicated
                  within the organisation. Are changes and decisions around changes usually well
                  communicated? Are they communicated in a timely way? Do you or your team members
                  usually know how they will work out in practice?
                  <br />
                  Lack of information about changes - how it will work out in practice and what
                  implications will be - creates uncertain situations that can be difficult to
                  handle with/cope with. The way your organisation communicates change may be
                  outside of your influence, but you could consider how you communicate with your
                  team when change is underway. Perhaps you could provide opportunities to discuss
                  any upcoming changes so that you can share any feedback with senior leaders.
                </p>
                <p>
                  At the team level: Think about how you usually manage changes within your team. Do
                  you communicate with team members in a timely way? Do your team members usually
                  know how and changes will work out in practice?
                  <br />
                  Lack of information about changes - how it will work out in practice and what
                  implications will be - creates uncertain situations that can be difficult to cope
                  with.
                </p>
                <h2 className="page-content__subtitle page-content__subtitle--section">
                  POOR IMPLEMENTATION
                </h2>
                <p>
                  At the organisational level: Think about how changes are usually designed and
                  conducted. Are you or your team members usually consulted for changes that will
                  directly affect their work or conditions?
                  <br />
                  Unfavourable consequences of a change can sometimes be avoided when people who are
                  experts of the job are consulted. You may think of the organisational provisions
                  for raising issues or for upward communication. Are there any? Are they effective?
                </p>
                <p>
                  At the team level: Think about how you usually design and conduct changes within
                  your teams. Do you usually consult your team members for changes that will
                  directly affect them?
                  <br />
                  Unfavourable consequences of change can sometimes be avoided when people who are
                  experts in the job are consulted. You may think of putting in place some ways for
                  participation or consultation through which your team members can share their
                  views and raise issues. Are there any? Are they seen as effective?
                </p>
              </div>
            </div>
          </>
        );
        break;
    }

    return (
      <div>
        {content} {modalContent}
      </div>
    );
  };

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (currentUser && currentUser.token) {
      retryFetch();
    }

    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  return (
    <>
      <ProgressBar loading={loading} />
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />
      <ErrorRender object={error} />

      {data && (
        <div className="grid-container">
          <Grid className="grid-padding-x align-justify">
            <Cell small={12}>
              <div className="page-content">{categoryContent(data.id)}</div>
            </Cell>
          </Grid>
        </div>
      )}
    </>
  );
}
