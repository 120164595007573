import './App.css';

// Routes
import { Route, Routes, useLocation } from 'react-router-dom';
import PrivateRoute from './helpers/PrivateRoute';
import PublicRoute from './helpers/PublicRoute';

// Components
import { Footer } from './shared/Footer';

// Routes
import RegistrationsNew from './pages/registrations/New';
import { RegistrationsEdit } from './pages/registrations/Edit';
import { RegistrationsShow } from './pages/registrations/Show';

import { PasswordResetsNew } from './pages/password-resets/New';
import { PasswordResetsEdit } from './pages/password-resets/Edit';
import { PasswordResetsShow } from './pages/password-resets/Show';

import { SessionsNew } from './pages/sessions/New';
import { SessionsDestroy } from './pages/sessions/Destroy';

import { AuthenticationsNew } from './pages/authentications/New';

import { PlaceholdersIndex } from './pages/shared/placeholders/Index';

import { ResourcesIndex } from './pages/resources/Index';
import { ResourcesShow } from './pages/resources/Show';
import { ResourcesManagerMeetingPrep } from './pages/resources/ManagerMeetingPrep';
import { ResourcesManagerTraining } from './pages/resources/ManagerTraining';

import { ResourcesManagerAdviceForLeading } from './pages/resources/ManagerAdviceForLeading';
import { ResourcesManagerRoleOfResources } from './pages/resources/ManagerRoleOfResources';
import { ResourcesManagerStressSource } from './pages/resources/ManagerStressSource';
import { ResourcesManagerTechnicalTraining } from './pages/resources/ManagerTechnicalTraining';
import { ResourcesManagerThingsToKnow } from './pages/resources/ManagerThingsToKnow';

import { PagesHowToUse } from './pages/pages/HowToUse';
import { PagesAboutFwd } from './pages/pages/AboutFwd';

import { ConversationsIndex } from './pages/conversations/Index';
import { ConversationsShow } from './pages/conversations/Show';
import ConversationsNew from './pages/conversations/New';

import { ConversationsScreeningsNew } from './pages/conversations/screenings/New';
import { ConversationsScreeningHomesShow } from './pages/conversations/screenings/homes/Show';
import { ConversationsScreeningRoutesShow } from './pages/conversations/screenings/routes/Show';

import { ConversationsFullQuestionsNew } from './pages/conversations/full-questions/New';
import { ConversationsFullQuestionsShow } from './pages/conversations/full-questions/Show';
import { ConversationsFullQuestionsEdit } from './pages/conversations/full-questions/Edit';
import { ConversationsCategoriesEdit } from './pages/conversations/categories/Edit';
import { ConversationsConfirmationsNew } from './pages/conversations/confirmations/New';

import { ConversationsManagersShow } from './pages/conversations/managers/Show';

import { CanvasesCategoriesNew } from './pages/canvases/categories/New';
import { CanvasesCategoriesEdit } from './pages/canvases/categories/Edit';
import { CanvasesCategoriesShow } from './pages/canvases/categories/Show';

import { CanvasesConfirmationsNew } from './pages/canvases/confirmations/New';
import { CanvasesExportsNew } from './pages/canvases/exports/New';

import { ErrorsNotFound } from './pages/errors/NotFound';

export const LocationDisplay = () => {
  const location = useLocation();

  return <div data-testid="location-display">{location.pathname}</div>;
};

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<PublicRoute Component={RegistrationsNew} redirectPath="/conversations" />}
        />

        {/* public */}
        <Route path="/registrations" element={<PublicRoute redirectPath="/conversations" />}>
          <Route path=":userId/:userToken" element={<RegistrationsEdit />} />
          <Route path="" element={<RegistrationsShow />} />
        </Route>

        <Route path="/password-resets">
          <Route path="new" element={<PasswordResetsNew />} />
          <Route path=":userId/:userToken" element={<PasswordResetsEdit />} />
          <Route path="" element={<PasswordResetsShow />} />
        </Route>

        <Route path="/sessions">
          <Route
            path="new"
            element={<PublicRoute Component={SessionsNew} redirectPath="/conversations" />}
          />
          <Route path="destroy" element={<SessionsDestroy />} />
        </Route>

        <Route path="/authentications">
          <Route
            path="new"
            element={<PublicRoute Component={AuthenticationsNew} redirectPath="/conversations" />}
          />
        </Route>

        {/* private */}
        <Route exact path="/pages" element={<PrivateRoute redirectPath="/sessions/new" />}>
          <Route path="how-to-use-this-tool" element={<PagesHowToUse />} />
          <Route path="about-fwd" element={<PagesAboutFwd />} />
        </Route>

        <Route exact path="/resources" element={<PrivateRoute redirectPath="/sessions/new" />}>
          <Route path="" element={<ResourcesIndex />} />
          <Route path="manager-meeting-prep" element={<ResourcesManagerMeetingPrep />} />
          <Route path="manager-training" element={<ResourcesManagerTraining />} />
          <Route path="advice-for-leading" element={<ResourcesManagerAdviceForLeading />} />
          <Route
            path="key-things-to-know-about-stress"
            element={<ResourcesManagerThingsToKnow />}
          />
          <Route path="main-source-of-stress" element={<ResourcesManagerStressSource />} />
          <Route path="role-of-resources" element={<ResourcesManagerRoleOfResources />} />
          <Route path="advice-for-leading" element={<ResourcesManagerAdviceForLeading />} />
          <Route path="technical-training" element={<ResourcesManagerTechnicalTraining />} />
          <Route path=":categoryId" element={<ResourcesShow />} />
        </Route>

        <Route exact path="/conversations" element={<PrivateRoute redirectPath="/sessions/new" />}>
          <Route path="new" element={<ConversationsNew />} />
          <Route path=":conversationId" element={<ConversationsShow />} />
          <Route path="destroy" element={<PlaceholdersIndex />} />
          <Route path="" element={<ConversationsIndex />} />
        </Route>

        <Route
          exact
          path="/conversations/:conversationId/full-questions"
          element={<PrivateRoute redirectPath="/sessions/new" />}
        >
          <Route path="new" element={<ConversationsFullQuestionsNew />} />
          <Route path=":pageNumber/edit" element={<ConversationsFullQuestionsEdit />} />
          <Route path="" element={<ConversationsFullQuestionsShow />} />
        </Route>

        <Route
          exact
          path="/conversations/:conversationId/categories"
          element={<PrivateRoute redirectPath="/sessions/new" />}
        >
          <Route path="edit" element={<ConversationsCategoriesEdit />} />
        </Route>

        <Route
          exact
          path="/conversations/:conversationId/confirmations"
          element={<PrivateRoute redirectPath="/sessions/new" />}
        >
          <Route path="new" element={<ConversationsConfirmationsNew />} />
        </Route>

        <Route
          exact
          path="/canvases/:conversationId/categories"
          element={<PrivateRoute redirectPath="/sessions/new" />}
        >
          <Route path="new" element={<CanvasesCategoriesNew />} />
          <Route path=":categoryId/edit" element={<CanvasesCategoriesEdit />} />
          <Route path=":categoryId" element={<CanvasesCategoriesShow />} />
        </Route>

        <Route
          exact
          path="/canvases/:conversationId/confirmations"
          element={<PrivateRoute redirectPath="/sessions/new" />}
        >
          <Route path="new" element={<CanvasesConfirmationsNew />} />
        </Route>

        <Route
          exact
          path="/canvases/:conversationId/exports"
          element={<PrivateRoute redirectPath="/sessions/new" />}
        >
          <Route path="new" element={<CanvasesExportsNew />} />
        </Route>

        <Route
          exact
          path="/conversations/:conversationId/team-member-prep/homes"
          element={<PrivateRoute redirectPath="/sessions/new" />}
        >
          <Route path="" element={<ConversationsScreeningHomesShow />} />
        </Route>

        <Route
          exact
          path="/conversations/:conversationId/team-member-prep/routes"
          element={<PrivateRoute redirectPath="/sessions/new" />}
        >
          <Route path="" element={<ConversationsScreeningRoutesShow />} />
        </Route>

        <Route
          exact
          path="/conversations/:conversationId/team-member-prep"
          element={<PrivateRoute redirectPath="/sessions/new" />}
        >
          <Route path="new" element={<ConversationsScreeningsNew />} />
        </Route>

        <Route
          exact
          path="/conversations/:conversationId/managers"
          element={<PrivateRoute redirectPath="/sessions/new" />}
        >
          <Route path="" element={<ConversationsManagersShow />} />
        </Route>

        {/* private - employers only */}
        <Route
          exact
          path="/admin/employees"
          element={<PrivateRoute redirectPath="/conversations" userRoleRequired="employer" />}
        >
          <Route path="" element={<PlaceholdersIndex />} />
        </Route>

        {/* private - employees only */}

        {/* public - errors */}
        <Route path="*" element={<ErrorsNotFound />} />
      </Routes>

      <Footer />
    </>
  );
}

export default App;
