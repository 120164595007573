const DluchApi = function (ApiRequestedPath, id = '') {
  const apiEnvironment = function () {
    let env;

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      env = 'development';
    } else if (process.env.JEST_WORKER_ID !== undefined) {
      env = 'test';
    } else {
      env = 'production';
    }

    return env;
  };

  this.requestPath = function () {
    let path;

    switch (ApiRequestedPath) {
      case 'PasswordResetsNew':
        path = '/api/account/forgotpassword';
        break;
      case 'PasswordResetsEdit':
        path = '/api/account/setpassword';
        break;
      case 'AuthenticationsNew':
        path = '/api/account/otp';
        break;
      case 'SessionsNew':
        path = '/api/account/login';
        break;
      case 'SessionsDestroy':
        path = '/api/logout';
        break;
      case 'RegistrationsNew':
        path = '/api/registration/register';
        break;
      case 'RegistrationsEdit':
        path = '/api/registration/verify';
        break;
      case 'ConversationsIndex':
        path = '/api/conversations';
        break;
      case 'ConversationsNew':
        path = '/api/conversation';
        break;
      case 'ConversationsEdit':
        path = '/api/conversation';
        break;
      case 'ConversationsShow':
        path = '/api/conversation/' + id;
        break;
      case 'ConversationsDestroy':
        path = '/api/conversation';
        break;
      case 'QuestionsCategoriesIndex':
        path = '/api/questioncategories';
        break;
      case 'QuestionsIndex':
        path = '/api/questions';
        break;
      case 'QuestionsNew':
        path = '/api/questionscores';
        break;
      case 'QuestionScoresShow':
        path = '/api/questionscores/' + id;
        break;
      case 'QuestionResultsShow':
        path = '/api/questionresults/' + id;
        break;
      case 'QuestionResultsEdit':
        path = '/api/questionresults';
        break;
      case 'UsersNew':
        path = '/api/registration/invite';
        break;
      case 'UsersShow':
        path = '/api/users';
        break;
      case 'ConversationDataShow':
        path = '/api/conversationdata/' + id;
        break;
      case 'ConversationDataEdit':
        path = '/api/conversationdata';
        break;
      default:
        path = '';
    }

    return path;
  };

  this.ApiRequestUrl = function () {
    console.info(this.requestHostname + this.requestPath());

    return this.requestHostname + this.requestPath();
  };

  this.requestHostname =
    process.env.JEST_WORKER_ID !== undefined
      ? 'http://localhost'
      : 'https://api-fwd-beta.azurewebsites.net';
  this.ApiEnvironment = apiEnvironment();
};

export default DluchApi;
