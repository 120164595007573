// Backend
import React from 'react';

// Frontend
import { Grid, Cell } from 'react-foundation';
import managerPrepImage from '../../images/content/pages/4-sm.png';
import teamPrepImage from '../../images/content/pages/9-sm.png';
import managerTrainingImage from '../../images/content/pages/11-sm.png';
import userGuideImage from '../../images/content/pages/16-sm.png';
import flowChartImage from '../../images/content/pages/FWD - FlowChart thumb-sm.png';
import canvasGuideImage from '../../images/content/pages/19-sm.png';
import '../../stylesheets/components/Pages.css';

// Functionality
import { useLocation, Link } from 'react-router-dom';

// Components
import { Header, FlashMessage } from '../../constants/SharedComponents';

export function ResourcesIndex() {
  // API
  const { state } = useLocation();

  // Objects
  const [flashMessageObject, setFlashMessageObject] = React.useState({});

  // Functions

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [state]);

  return (
    <>
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />
      <div className="grid-container">
        <Grid className="grid-padding-x align-justify">
          <Cell small={12}>
            <div class="page-content">
              <h1 class="page-content__title">Resources</h1>
            </div>
          </Cell>
          <Cell small={12} large={6}>
            <div class="page-content">
              <div class="page-content__styles">
                <Link
                  to={process.env.PUBLIC_URL + '/Manager Preparation Guidance.pdf'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="image-container">
                    <img src={managerPrepImage} loading="lazy" alt="Manager Preparation Guidance" />
                    <div class="image-overlay">
                      <h3>Manager Preparation Guidance</h3>
                    </div>
                  </div>
                </Link>
                <p>
                  To help you prepare for the coming conversation, the following activity is
                  recommended.
                </p>
                <Link
                  to={process.env.PUBLIC_URL + '/Manager Preparation Guidance.pdf'}
                  target="_blank"
                  rel="noopener noreferrer"
                  class="page-content__button"
                >
                  Download Manager Preparation Guidance PDF
                </Link>
              </div>
            </div>
          </Cell>
          <Cell small={12} large={6}>
            <div class="page-content">
              <div class="page-content__styles">
                <Link
                  to={process.env.PUBLIC_URL + '/Team Member Preparation Guidance.pdf'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="image-container">
                    <img
                      src={teamPrepImage}
                      loading="lazy"
                      alt="Team Member Preparation Guidance"
                    />
                    <div class="image-overlay">
                      <h3>Team Member Preparation Guidance</h3>
                    </div>
                  </div>
                </Link>
                <p>
                  To help you prepare for the coming conversation, the following activity is
                  recommended.
                </p>
                <Link
                  to={process.env.PUBLIC_URL + '/Team Member Preparation Guidance.pdf'}
                  target="_blank"
                  rel="noopener noreferrer"
                  class="page-content__button"
                >
                  Download Team Member Preparation Guidance PDF
                </Link>
              </div>
            </div>
          </Cell>
          <Cell small={12}>
            <hr />
          </Cell>
          <Cell small={12} large={6}>
            <div class="page-content">
              <div class="page-content__styles">
                <Link
                  to={process.env.PUBLIC_URL + '/Manager Training.pdf'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="image-container">
                    <img src={managerTrainingImage} loading="lazy" alt="Manager Training" />
                    <div class="image-overlay">
                      <h3>Manager Training</h3>
                    </div>
                  </div>
                </Link>
                <p>
                  To help you prepare for the coming conversation, the following activity is
                  recommended.
                </p>
                <Link
                 to={process.env.PUBLIC_URL + '/Manager Training.pdf'}
                  target="_blank"
                  rel="noopener noreferrer"
                  class="page-content__button"
                >
                  Download Manager Training PDF
                </Link>
              </div>
            </div>
          </Cell>
          <Cell small={12} large={6}>
            <div class="page-content">
              <div class="page-content__styles">
                <Link
                  to={process.env.PUBLIC_URL + '/Canvas Guidance.pdf'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="image-container">
                    <img src={canvasGuideImage} loading="lazy" alt="Conversation Canvas Guide" />
                    <div class="image-overlay">
                      <h3>Conversation Canvas Guidance</h3>
                    </div>
                  </div>
                </Link>
                <p>Guidance for managers on how to use the conversation canvas.</p>
                <Link
                 to={process.env.PUBLIC_URL + '/Canvas Guidance.pdf'}
                  target="_blank"
                  rel="noopener noreferrer"
                  class="page-content__button"
                >
                  Download Conversation Canvas Guidance PDF
                </Link>
              </div>
            </div>
          </Cell>
          <Cell small={12}>
            <hr />
          </Cell>
          <Cell small={12} large={6}>
            <div class="page-content">
              <div class="page-content__styles">
                <Link
                  to={process.env.PUBLIC_URL + '/FWD - How to guide.pdf'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="image-container">
                    <img src={userGuideImage} loading="lazy" alt="" />
                    <div class="image-overlay">
                      <h3>User Guide</h3>
                    </div>
                  </div>
                </Link>

                <p>How to use the tool.</p>
                <Link
                 to={process.env.PUBLIC_URL + '/FWD - How to guide.pdf'}
                  target="_blank"
                  rel="noopener noreferrer"
                  class="page-content__button"
                >
                  Download User Guide PDF
                </Link>
              </div>
            </div>
          </Cell>
          <Cell small={12} large={6}>
            <div class="page-content">
              <div class="page-content__styles">
                <Link
                  to={process.env.PUBLIC_URL + '/FWD - FlowChart.pdf'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="image-container">
                    <img src={flowChartImage} loading="lazy" alt="Flow Chart" />
                    <div class="image-overlay">
                      <h3>Flow Chart</h3>
                    </div>
                  </div>
                </Link>
                <p>Your FWD journey</p>
                <Link
                 to={process.env.PUBLIC_URL + '/FWD - FlowChart.pdf'}
                  target="_blank"
                  rel="noopener noreferrer"
                  class="page-content__button"
                >
                  Download Flow Chart PDF
                </Link>
              </div>
            </div>
          </Cell>
          <Cell small={12} large={6}></Cell>
          <Cell small={12}>
            <hr />
          </Cell>
        </Grid>
      </div>
    </>
  );
}
