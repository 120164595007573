// Backend
import React from 'react';

// Params
import useCurrentUser from '../hooks/useCurrentUser';

// Frontend
import { Grid, Cell } from 'react-foundation';
import futureWorkDesignLogo from '../images/layout/header/future-work-design-logo.png';
import '../stylesheets/layout/header/Primary.css';
import '../stylesheets/components/navigation/Header.css';

// Functionality
import { Link } from 'react-router-dom';

const Header = ({ onCurrentUserChange }) => {
  const currentUser = useCurrentUser();

  const FwdLogo = function ({ loggedIn }) {
    return (
      <Link
        to={loggedIn ? '/conversations' : '/'}
        className="primary-header__link"
        id="page-navigation-button--home"
      >
        <img
          src={futureWorkDesignLogo}
          loading="lazy"
          width="170"
          height="100"
          alt="Future Work Design"
          className="primary-header__image"
        />
      </Link>
    );
  };

  const HeaderLogos = function ({ loggedIn }) {
    return (
      <>
        <FwdLogo loggedIn={loggedIn} />
      </>
    );
  };

  const HeaderLinks = function ({ role }) {
    return (
      <>
        <ul className="primary-header-navigation-list">
          {role === 'employer' && (
            <li className="primary-header-navigation-list__item">
              <Link to="/employers" className="primary-header-navigation-list__link">
                Admin Panel
              </Link>
            </li>
          )}

          <li className="primary-header-navigation-list__item">
            <Link to="/conversations" className="primary-header-navigation-list__link">
              Dashboard
            </Link>
          </li>

          <li className="primary-header-navigation-list__item">
            <Link to="/pages/how-to-use-this-tool" className="primary-header-navigation-list__link">
              How to use this tool
            </Link>
          </li>

          <li className="primary-header-navigation-list__item">
            <Link to="/resources" className="primary-header-navigation-list__link">
              Knowledge Hub
            </Link>
          </li>

          <li className="primary-header-navigation-list__item">
            <Link to="/pages/about-fwd" className="primary-header-navigation-list__link">
              About FWD
            </Link>
          </li>
        </ul>
      </>
    );
  };

  const HeaderSessionsButton = function ({ loggedIn }) {
    return (
      <>
        {loggedIn && (
          <Link
            to="/sessions/destroy"
            className="primary-header__button"
            id="page-navigation-button--sessions"
          >
            Sign-Out
          </Link>
        )}

        {!loggedIn && (
          <Link
            to="/sessions/new"
            className="primary-header__button"
            id="page-navigation-button--sessions"
          >
            Sign In
          </Link>
        )}
      </>
    );
  };

  const loggedOut = function () {
    return (
      <>
        <header className="primary-header-wrap">
          <div className="primary-header">
            <div className="grid-container">
              <Grid className="align-middle">
                <Cell small={12} large={6}>
                  <HeaderLogos />
                </Cell>

                <Cell small={12} large={6} className="text-right">
                  <HeaderSessionsButton loggedIn={false} />
                </Cell>
              </Grid>
            </div>
          </div>
        </header>
      </>
    );
  };

  const loggedIn = function (role) {
    return (
      <>
        <header className="primary-header-wrap">
          <div className="primary-header">
            <div className="grid-container">
              <Grid className="align-middle">
                <Cell small={12} large={3}>
                  <HeaderLogos loggedIn={true} />
                </Cell>

                <Cell small={12} large={6}>
                  <HeaderLinks role={role} />
                </Cell>

                <Cell small={12} large={3} className="text-right">
                  <span className="primary-header__microcopy">
                    Logged in as: {currentUser.firstname}
                  </span>
                  <HeaderSessionsButton loggedIn={true} />
                </Cell>
              </Grid>
            </div>
          </div>
        </header>
      </>
    );
  };

  const currentUserHeader = function (roles) {
    let roleComponent;

    if (roles.includes('Manager')) {
      roleComponent = 'employer';
    }

    if (roles.includes('User')) {
      roleComponent = 'employee';
    }

    return loggedIn(roleComponent);
  };

  const header = function () {
    let headerComponent;

    if (!currentUser || onCurrentUserChange) {
      headerComponent = loggedOut();
    } else {
      headerComponent = currentUserHeader(currentUser.roles.map((role) => role.roleName));
    }

    return headerComponent;
  };

  return header();
};

export default Header;
