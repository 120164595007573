// Backend
import React from 'react';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality
import { useLocation, Link } from 'react-router-dom';

// Components
import { Header, FlashMessage } from '../../constants/SharedComponents';
import useCurrentUser from '../../hooks/useCurrentUser';

export function ResourcesManagerTraining() {
  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();

  // Objects
  const [flashMessageObject, setFlashMessageObject] = React.useState({});

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  return (
    <>
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />

      <div className="grid-container">
        <Grid className="grid-padding-x align-justify">
          <Cell small={12}>
            <div class="page-content">
              <h1 class="page-content__title">Welcome to your introductory manager training</h1>
              <div class="page-content__styles">
                <p>
                  To help you prepare for your first conversation with your team members, some
                  activities have been designed to help you:
                </p>
                <ul>
                  <li>
                    Better understand the issue of work-related stress and its impact on employee
                    mental health and wellbeing
                  </li>
                  <li>Better know how you can provide support to your team members</li>
                </ul>
                <p>
                  You are free to navigate through the different sections. For your first
                  conversation, it is recommended to explore all sections in the order that is
                  presented here.
                </p>
                <ul>
                  <li>
                    <Link to="/resources/key-things-to-know-about-stress">
                      Key things to know about stress
                    </Link>
                  </li>
                  <li>
                    <Link to="/resources/main-source-of-stress">
                      Main source of stress in the workplace (work characteristics)
                    </Link>
                  </li>
                  <li>
                    <Link to="/resources/role-of-resources">Role of resources</Link>
                  </li>
                  <li>
                    <Link to="/resources/advice-for-leading">
                      Advice for leading the conversation
                    </Link>
                  </li>
                  <li>
                    <Link to="/resources/technical-training">
                      Technical training for your first conversation
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  );
}
