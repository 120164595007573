// Backend
import React from 'react';
import axios from 'axios';

// Frontend
import { Download, ChevronUp } from 'react-bootstrap-icons';
import { Grid, Cell } from 'react-foundation';

// Functionality
import { useParams, useLocation, Link } from 'react-router-dom';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  ProgressBar,
  ErrorRender,
  Flashable,
  setToken,
} from '../../../constants/SharedComponents';

import useCurrentUser from '../../../hooks/useCurrentUser';

export function CanvasesConfirmationsNew() {
  // Params
  const { conversationId } = useParams();

  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();
  const token = setToken(currentUser);
  const headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };

  // Objects
  const [data, setData] = React.useState(null);
  const [categoriesData, setCategoriesData] = React.useState(null);
  const [canvasData, setCanvasData] = React.useState(null);
  const [submissionUnlocked, setSubmissionUnlocked] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  // Functions

  const onSave = React.useCallback(() => {
    setLoading(true);
    let Api = new DluchApi('ConversationDataEdit');

    let requestPayload = {
      conversationId: conversationId,
      canvasData: JSON.stringify(canvasData),
      isFinished: true,
    };

    axios
      .put(Api.ApiRequestUrl(), requestPayload, headerParams)
      .then(() => {
        setSubmissionUnlocked(false);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          setFlashMessageObject(Flashable(error.response.data.errorDesc, 'alert', false));
        } else {
          setFlashMessageObject(Flashable(error.message, 'alert', false));
        }
      });

    setLoading(false);
  }, [canvasData]);

  const getData = async () => {
    setLoading(true);

    try {
      let Api = new DluchApi('ConversationsShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.conversation;
      console.dir(responseData);
      setData(responseData);
      setError(false);
      getCategoryData();
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getCategoryData = async () => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionResultsShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.results.filter(
        (obj) => obj.isConversationTopic === true,
      );

      for (let row of responseData) {
        row.title = row.optionalCategoryName === null ? row.categoryName : row.optionalCategoryName;
      }

      setCategoriesData(responseData);
      setError(false);
      getCanvasData();
    } catch (err) {
      console.dir(err.message);
      setError(false);
      getCanvasData();
    } finally {
      setLoading(false);
    }
  };

  const getCanvasData = async (category) => {
    setLoading(true);

    try {
      let Api = new DluchApi('ConversationDataShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.data;
      let canvasData = JSON.parse(responseData.canvasData);
      let locked = responseData.isFinished;

      if (locked) {
        setSubmissionUnlocked(false);
      } else {
        setSubmissionUnlocked(true);
      }

      console.dir(canvasData);
      setCanvasData(canvasData);
      setError(false);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  function retryFetch() {
    setLoading(true);
    setError(false);
    setData(null);
    getData();
  }

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (currentUser && currentUser.token) {
      retryFetch();
    }

    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  return (
    <>
      <ProgressBar loading={loading} />
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />
      <ErrorRender object={error} />

      {data && (
        <>
          <div className="page-content">
            <div className="page-content__header page-content__header--bordered page-content__header--fixed">
              <div className="grid-container">
                <Grid className="grid-padding-x align-middle">
                  <Cell small={12} large={2}>
                    <Link
                      to="/conversations"
                      className="page-content__button"
                      id="page-navigation-button--go-back"
                    >
                      Back
                    </Link>
                  </Cell>

                  <Cell small={12} large={5} className="text-center">
                    <ul className="canvas-categories-list">
                      {!categoriesData &&
                        Array.apply(null, { length: 3 }).map((count, categoriesDataIndex) => (
                          <li
                            className="canvas-categories-list__item"
                            key={'category-' + categoriesDataIndex}
                          >
                            <Link
                              to={`/canvases/${data.id}/categories/${categoriesDataIndex + 1}`}
                              state={{ state: { categoryId: categoriesDataIndex + 1 } }}
                              className="canvas-categories-list__link"
                              id={`canvas-category-list-link--${categoriesDataIndex}`}
                            >
                              {`Topic ${categoriesDataIndex + 1}`}
                            </Link>
                          </li>
                        ))}

                      {categoriesData && (
                        <>
                          {categoriesData.map((category, categoriesDataIndex) => {
                            return (
                              <li
                                className="canvas-categories-list__item"
                                key={'category-' + category.categoryId}
                              >
                                <Link
                                  to={`/canvases/${data.id}/categories/${categoriesDataIndex + 1}`}
                                  state={{ state: { categoryId: categoriesDataIndex + 1 } }}
                                  className="canvas-categories-list__link"
                                  id={`canvas-category-list-link--${categoriesDataIndex}`}
                                >
                                  {`Topic ${categoriesDataIndex + 1}`}
                                  <span className="canvas-categories-list__icon canvas-categories-list__icon--deselected">
                                    <ChevronUp size={12} />
                                  </span>
                                </Link>
                              </li>
                            );
                          })}
                        </>
                      )}
                    </ul>
                  </Cell>

                  <Cell small={12} large={5} className="text-right">
                    <ul className="canvas-categories-navigation-list">
                      <li className="canvas-categories-navigation-list__item">
                        <Link
                          to={`/canvases/${data.id}/exports/new`}
                          id="page-navigation-button--download"
                          className="canvas-categories-navigation-list__save-button"
                        >
                          Download PDF
                          <span className="canvas-categories-navigation-list__icon">
                            <Download size={14} />
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </Cell>
                </Grid>
              </div>
            </div>
          </div>

          <div className="grid-container">
            <Grid className="grid-padding-x">
              <Cell small={12}>
                <div className="page-content page-content--margin">
                  {canvasData && submissionUnlocked && (
                    <>
                      <h1 className="page-content__title">Complete your conversation</h1>

                      <div className="page-content__styles">
                        <p>
                          This will lock the content and mean that it cannot be changed again.
                          Please ensure that you have completed the content for each Topic before
                          marking the conversation as complete,
                        </p>
                      </div>

                      <Link
                        to={`/canvases/${data.id}/categories/new`}
                        className="page-content__button page-content__button--inline"
                        id="page-navigation-button--return"
                      >
                        Back to conversation
                      </Link>

                      <button
                        onClick={onSave}
                        className="basic-form__button"
                        id="page-form-button--submit"
                      >
                        Mark as complete
                      </button>
                    </>
                  )}

                  {canvasData && !submissionUnlocked && (
                    <>
                      <p>Thank you, your conversation is now marked as complete.</p>
                    </>
                  )}
                </div>
              </Cell>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}
