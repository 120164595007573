// Backend
import React from 'react';
import axios from 'axios';

// Components
import { DluchApi } from '../constants/SharedComponents';

// Functionality
import { useNavigate } from 'react-router-dom';

const RefreshToken = function () {
  const navigate = useNavigate();

  const parsedUserData = function () {
    if (localStorage.getItem('userDetails')) {
      try {
        return JSON.parse(localStorage.getItem('userDetails'));
      } catch {}
    }
  };

  const [tokenRefreshStatus, setTokenRefreshStatus] = React.useState(null);

  const refreshToken = function () {
    const Api = new DluchApi('QuestionsCategoriesIndex');
    const token = parsedUserData().refreshToken;

    let requestPayload = { refreshToken: token };

    axios
      .post(Api.ApiRequestUrl(), requestPayload, { timeout: 30000 })
      .then(() => {
        setTokenRefreshStatus(true);
      })
      .catch(() => {
        localStorage.removeItem('userDetails');
        setTokenRefreshStatus(false);
        navigate('/sessions/new');
      });
    return false;
  };

  const expiredToken = function () {
    return new Date().getTime() > new Date(parsedUserData().expiresAt).getTime();
  };

  this.tokenValid = function () {
    let validToken = true;

    if (parsedUserData() && expiredToken()) {
      refreshToken();
      validToken = tokenRefreshStatus;
    }

    return validToken;
  };

  this.tokenRefresh = function () {
    refreshToken();
  };
};

export default RefreshToken;
