// Backend
import React from 'react';

// Frontend
import { Grid, Cell } from 'react-foundation';
import howDoesStressEmerge from '../../images/content/resourcesManagerThingsToKnow/how-does-stress-emerge.png';

// Functionality
import { useLocation, Link } from 'react-router-dom';

// Components
import { Header, FlashMessage } from '../../constants/SharedComponents';
import useCurrentUser from '../../hooks/useCurrentUser';

export function ResourcesManagerThingsToKnow() {
  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();

  // Objects
  const [flashMessageObject, setFlashMessageObject] = React.useState({});

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  return (
    <>
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />

      <div className="grid-container">
        <Grid className="grid-padding-x align-justify">
          <Cell small={12}>
            {
              // subnav here
            }
            <div class="page-content">
              <div class="page-content__header">
                <Link to="/resources/manager-training" class="page-content__button">
                  Back
                </Link>
              </div>
              <h1 class="page-content__title">
                Key things you should know about stress before you start{' '}
              </h1>
              <p>
                You are going to discuss with your team member some topics that may be sensitive or
                upsetting. For this conversation to go as well as possible, here is some information
                for you about stress.{' '}
              </p>
              <div class="page-content__styles">
                <h2 class="page-content__subtitle page-content__subtitle--section">
                  Stress is a complicated issue for several reasons.
                </h2>
                <h3 class="page-content__subtitle page-content__subtitle--section">
                  Stress always something negative (Agree/Disagree?)
                </h3>
                <p>
                  Stress is not always negative. In some cases, stress is a mobilising, activating
                  and a motivating agent. However, the negative form of work-related stress is
                  considered to emerge when demands in the work context exceeds the resources of the
                  person (e.g., energy levels).{' '}
                </p>
                <h3 class="page-content__subtitle page-content__subtitle--section">
                  Stress has the same meaning for everyone (Agree/Disagree?)
                </h3>
                <p>
                  What is perceived as stressful for one individual may not cause stress to another.
                  So, being exposed to the same situation can lead to stress for one person but not
                  for another. Individual differences can explain differences in perception. For
                  example, lack of flexibility over working patterns can cause stress for a person
                  who needs to fulfil both work and family duties while it may have little effect
                  (or be beneficial) for a person who enjoys working varied and non-traditional
                  hours. Other relevant individual differences can be: level of experience, what
                  motivates the person or personality. During the process of building a conversation
                  in this tool, you will be presented with a range of work characteristics that are
                  highlighted in scientific literature to cause work-related stress. These work
                  characteristics have been identified as the most common potential triggers to
                  generate work-related stress. It is important to remember that there can be other
                  sources of stress, beyond this list and that different working conditions can
                  affect your team members in very different ways.
                </p>
                <h3 class="page-content__subtitle page-content__subtitle--section">
                  Stress can be caused by multiple things at a time (Agree/Disagree?)
                </h3>
                <p>
                  Stress can be multicausal. A combination of different risks in the workplace can
                  lead to stress. It is important to think about the person as a whole, rather than
                  considering each risk as being completely independent from one another, so feel
                  free to bring other aspects of risk into each conversation, if you think it is
                  helpful to understand the experiences of your team member.
                </p>
                <h3 class="page-content__subtitle page-content__subtitle--section">
                  Stress is only psychological (Agree/Disagree?)
                </h3>
                <p>
                  <strong>The manifestations and signs of stress can take various forms.</strong>
                </p>
                <p>
                  These can be physical (e.g., bad quality sleep, headaches, illness, fatigue),
                  psychological (e.g., tension, anxiety, irritation) and behavioural signs (e.g.,
                  lower job performance, turnover intention, absenteeism, mistakes and errors). Some
                  signs are more noticeable than others. These signs are not necessarily caused by
                  risks in the workplace. Yet, the only way to know is to ask your team member about
                  their current experiences.{' '}
                </p>
                <h3 class="page-content__subtitle page-content__subtitle--section">
                  Stress is the result of individual weakness (Agree/Disagree?)
                </h3>
                <p>
                  <strong>
                    Work-related stress does not occur because of individual failure or weakness
                  </strong>
                </p>
                <p>
                  Although work-related stress depends on individual perception/experiences, all
                  employees are exposed to different levels of risk at work. The tipping point,
                  where it is too much, may be different from one person to another depending among
                  other things on the level of resources people have, with some having more
                  resources than others. Some resources are internal (e.g., problem-solving style,
                  having time or control over the situation) and others are external (e.g.,
                  organisational provisions, social support). Therefore, work-related stress can be
                  linked to a mismatch between resources (internal and external) and demands, rather
                  than individual failure or weakness.{' '}
                </p>
                <h3 class="page-content__subtitle page-content__subtitle--section">
                  How does stress emerge?
                </h3>
                <p>
                  There are different perceptions of work-related stress. What is presented here is
                  a simplification of one of the most commonly accepted views.
                </p>
                <img src={howDoesStressEmerge} loading="lazy" alt="" />
                <h3 class="page-content__subtitle page-content__subtitle--section">
                  What are the features of stressors?
                </h3>
                <p>
                  The academic literature has identified some features of stressors, which are
                  conditions or situations that can cause work stress:
                </p>
                <ul>
                  <li>frequency (is it a temporary or a recurrent situation?)</li>
                  <li>intensity</li>
                  <li>duration (how long will the situation last?)</li>
                  <li>severity (are its consequences very severe or mild?)</li>
                  <li>
                    novelty (is it something we've never faced or do we know how to handle it?)
                  </li>
                  <li>controllability (can we influence or control its effects ?)</li>
                  <li>predictability (do we know how it's going to evolve?)</li>
                </ul>
                <p>
                  It may be helpful to reflect on these features of the work situation that are
                  discussed with your team member. For example, how frequently is this an issue and
                  how long might these circumstances last? Thinking through these features of the
                  stressors might also help you to manage the risks they present.
                </p>
              </div>
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  );
}
