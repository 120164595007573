// Backend
import React from 'react';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality
import { useLocation, Link } from 'react-router-dom';

// Components
import { Header, FlashMessage } from '../../constants/SharedComponents';
import useCurrentUser from '../../hooks/useCurrentUser';

export function ResourcesManagerTechnicalTraining() {
  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();

  // Objects
  const [flashMessageObject, setFlashMessageObject] = React.useState({});

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  return (
    <>
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />

      <div className="grid-container">
        <Grid className="grid-padding-x align-justify">
          <Cell small={12}>
            {
              // subnav here
            }
            <div class="page-content">
              <div class="page-content__header">
                <Link to="/resources/manager-training" class="page-content__button">
                  Back
                </Link>
              </div>
              <h1 class="page-content__title">Leading the conversation</h1>
              <div class="page-content__styles">
                <p>
                  <strong>How to create an atmosphere that encourages dialogue</strong>
                </p>
                <p>
                  It is important for your team member feels that they are in a safe environment to
                  have the conversation. Here are some tips to encourage this:
                </p>
                <p>
                  <strong>
                    <u>Do:</u>
                  </strong>
                </p>
                <ul>
                  <li>
                    Be factual - being factual helps to bring more objectivity in the conversation.
                    Instead of saying "you are really difficult to work with", you can give an
                    example: "for me, it was difficult to work with you on this project
                    because&hellip;"
                  </li>
                  <li>Show respect - even if you don't agree with your team member</li>
                  <li>
                    Show interest in what your team member is saying - you can show interest by
                    reformulating, e.g., "from what you are saying, I understand that this situation
                    is complicated for you for this and this reason, am I right?" or by asking
                    confirmation, e.g., "I've got the feeling that this is putting you at strain, is
                    it right?". If you don't understand what your team member feels about this
                    situation, it is also fine to ask: "I'm not sure I understand what you're trying
                    to say, can you give me more details or an example?".
                  </li>
                  <li>
                    Show an empathic understanding - showing empathy allows you to put yourself in
                    others' shoes. It doesn't mean that you have to agree or have to feel what your
                    team member is feeling. Showing empathy is about truly listening to what they
                    are saying, recognising their emotions and trying to understand their point of
                    view even if you don't agree. To understand your team member, you may ask
                    yourself{' '}
                    <strong>with no preconception or judgement about what is right or wrong</strong>
                    : "If I were them, how would I feel in this situation?". Avoid minimising or
                    rejecting what the other feels, e.g., "you have absolutely no reason to worry",
                    "it is a bit of a problem but it is not that bad!" An empathic attitude would
                    be: "I can see that it upsets you", "it is normal to feel that way in this
                    situation".
                  </li>
                </ul>
                <p>
                  <strong>
                    <u>Avoid:</u>
                  </strong>
                </p>
                <ul>
                  <li>
                    Having a defensive attitude - the conversation may bring up issues for which you
                    may be asked to reflect on your managerial practices, which can be difficult. It
                    is never easy to receive criticism. However, it is best to see this moment as a
                    feedback on your work and not as an attack on who you are.
                  </li>
                  <li>
                    Letting your preconceived ideas influence how you treat your team member&rsquo;s
                    situation. Your relationship may not easy with all your team members. If this is
                    a case, focus on showing commitment to the aim of the process, so that you can
                    start the conversation on the right foot. You can say: "Let's put former
                    grievances aside to move toward together, this time is about how we can together
                    develop a plan for you to feel better supported. As your manager, I commit to
                    help you feel better supported". Showing commitment can help your team member to
                    open themselves. However, be aware that commitment is not empty words, it also
                    engages your responsibility and can break your team member trust if you do no
                    respect it.&nbsp;
                  </li>
                </ul>
                <p>
                  <strong>What if my team member is reluctant to talk with me?</strong>
                </p>
                <p>
                  The advice above should help your team member to be honest open and feel at ease.
                  However, if you perceive that your team member is still resistant, you can help
                  them think about a person that they will be more comfortable to have this
                  conversation.&nbsp;
                </p>
                <p>
                  <strong>How to start the conversation? </strong>
                </p>
                <p>
                  The conversation is intended to be flexible even though the tool guides you. You
                  should agree with your team member on the best way for the conversation to flow.
                  Are you both happy to follow the guidelines or do you prefer to let the
                  conversation naturally flow? Does your team member prefer to lead the conversation
                  with the points they'd like to discuss, and you prompting questions?
                </p>
                <p>
                  <strong>What if my team member raises an issue out of my control? </strong>
                </p>
                <p>
                  This tool is designed to be an exploration and problem-solving tool for managers
                  to support their team members.&nbsp; It is likely that some aspects of the work
                  characteristics that are identified as concerning may be outside of your control
                  to address. &nbsp;You may need to raise an issue with your own manager with the
                  aim of informing higher management and getting advice. You may ask your manager:
                  regarding this issue, how can I best support my team member?
                </p>
              </div>
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  );
}
