import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import { unstable_HistoryRouter as Router } from 'react-router-dom';

import 'foundation-sites/dist/css/foundation.min.css';

import './stylesheets/layout/Generic.css';
import './stylesheets/components/forms/Shared.css';

import App from './App';
import history from './helpers/history';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router history={history}>
    <App />
  </Router>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
