// Backend
import React from 'react';
import axios from 'axios';

// Frontend
import { ChevronUp } from 'react-bootstrap-icons';
import { Grid, Cell } from 'react-foundation';
import '../../../stylesheets/components/conversations/Canvas.css';

// Functionality
import { useParams, useLocation, Link } from 'react-router-dom';
import format from 'date-fns/format';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  ProgressBar,
  ErrorRender,
  setToken,
  filteredCategories,
} from '../../../constants/SharedComponents';

import useCurrentUser from '../../../hooks/useCurrentUser';

// react-pdf - core
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
  PDFDownloadLink,
  PDFViewer,
} from '@react-pdf/renderer';

// ReactFlow - core
import ReactFlow, { ReactFlowProvider, useEdgesState, Background } from 'reactflow';
import 'reactflow/dist/style.css';
import { toPng } from 'html-to-image';
import ButtonEdge from '../../../components/ReactFlow/ButtonEdge';

// ReactFlow - nodes
import ImpactNode from '../../../components/ReactFlow/ImpactNode';
import CategoryNode from '../../../components/ReactFlow/CategoryNode';
import WhyNode from '../../../components/ReactFlow/WhyNode';

const nodeTypes = {
  impactNode: ImpactNode,
  categoryNode: CategoryNode,
  whyNode: WhyNode,
};

const defaultViewport = { x: 0, y: 0, zoom: 0.75 };
const snapGrid = [25, 25];

const styles = StyleSheet.create({
  page: {
    padding: 10,
  },
});

const edgeTypes = {
  buttonedge: ButtonEdge,
};

// Create Document Component
const MyDocument = ({ reactFlowsImages, canvasData, conversationData }) => {
  console.dir(canvasData);
  console.dir(conversationData);

  var controlsCountTotal = {};
  var actionsCountTotal = {};

  let date = new Date(conversationData.updatedAt);
  let dateTime = format(date, 'dd/MM/yy');

  for (const [key, value] of Object.entries(canvasData)) {
    console.dir(key);

    let controlsCount = 0;
    let actionsCount = {};

    if (canvasData[key].additionalInput) {
      for (let row of Object.keys(canvasData[key].additionalInput)) {
        if (row.includes('controls--')) {
          let value = row.split('--')[1];

          if (value > controlsCount) {
            controlsCount = parseInt(value);
          }
        }

        if (row.includes('actions--')) {
          let values = row.split('--')[1].split('-');
          let control = parseInt(values[0]);
          let actionsCountTotal = parseInt(values[1]);

          if (actionsCount[control] === undefined || actionsCountTotal > actionsCount[control]) {
            actionsCount[control] = actionsCountTotal;
          }
        }
      }

      for (const [key, value] of Object.entries(actionsCount)) {
        actionsCount[key] = value + 1;
      }

      controlsCount += 1;

      controlsCountTotal[key] = controlsCount;
      actionsCountTotal[key] = actionsCount;
    }
  }

  console.dir(controlsCountTotal);
  console.dir(actionsCountTotal);

  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <Text>Title page</Text>
          <Text style={{ margin: 10 }}>Attendee: {conversationData.attendeeName}</Text>
          <Text style={{ margin: 10 }}>Organiser: {conversationData.organiserName}</Text>
          <Text style={{ margin: 10 }}>Completion date: {dateTime}</Text>
        </Page>
        {Array.apply(null, { length: Object.keys(canvasData).length }).map(
          (count, categoryIndex) => (
            <Page size="A4" style={styles.page} key={`category-canvas-${categoryIndex + 1}`}>
              <Text>
                {
                  // !category.selfGuided ? `Risk ${categoryIndex + 1}: ${category.title}`: `Risk ${categoryIndex + 1}`
                }
                Topic {categoryIndex + 1}
              </Text>

              {reactFlowsImages && reactFlowsImages.hasOwnProperty(categoryIndex + 1) && (
                <Image src={reactFlowsImages[categoryIndex + 1]} wrap={false} />
              )}

              {canvasData[categoryIndex + 1] &&
                canvasData[categoryIndex + 1].additionalInput &&
                Array.apply(null, { length: controlsCountTotal[categoryIndex + 1] }).map(
                  (count, controlsIndex) => (
                    <View key={`control` + controlsIndex}>
                      <Text>Controls</Text>
                      <Text style={{ margin: 10 }}>
                        {
                          canvasData[categoryIndex + 1].additionalInput[
                            `canvas-category-controls--${controlsIndex}`
                          ]
                        }
                        <br />
                      </Text>

                      <Text>Actions</Text>

                      {Array.apply(null, {
                        length: actionsCountTotal[categoryIndex + 1][controlsIndex],
                      }).map((count, actionsIndex) => (
                        <Text
                          style={{ margin: 10 }}
                          key={`control-${controlsIndex}-action-${actionsIndex}`}
                        >
                          {
                            canvasData[categoryIndex + 1].additionalInput[
                              `canvas-category-actions--${controlsIndex}-${actionsIndex}`
                            ]
                          }
                          <br />
                        </Text>
                      ))}
                    </View>
                  ),
                )}
            </Page>
          ),
          canvasData,
        )}
      </Document>
    </>
  );
};

function Flow(props) {
  return <ReactFlow {...props} />;
}

export function CanvasesExportsNew() {
  // Params
  const { conversationId } = useParams();

  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();
  const token = setToken(currentUser);
  const headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };

  // Objects
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [submissionUnlocked, setSubmissionUnlocked] = React.useState(false);
  const [categoriesData, setCategoriesData] = React.useState(null);
  const [canvasData, setCanvasData] = React.useState({});
  const [reactFlowsImages, setReactFlowsImage] = React.useState({});

  // ReactFlow - core
  const reactFlowWrapper = React.useRef(null);
  const [onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = React.useState(null);

  // ReactFlow - functionality
  const onInit = React.useCallback((reactFlowInstance) => {
    setReactFlowInstance(reactFlowInstance);
  }, []);

  const downloadImage = (categoryIndex) => {
    console.info('Img for ' + categoryIndex);
    if (document.querySelector(`.js--reactflow-${categoryIndex}`)) {
      const dataUrl = toPng(document.querySelector(`.js--reactflow-${categoryIndex}`));
      let reactFlowImageRenders = reactFlowsImages;
      reactFlowImageRenders[categoryIndex] = dataUrl;
      setReactFlowsImage(reactFlowImageRenders);
    } else {
      console.warn('Element missing');
    }
  };

  // Functions
  const getData = async () => {
    setLoading(true);

    try {
      let Api = new DluchApi('ConversationsShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.conversation;
      const conversationSubmissionUnlocked = responseData.employeePrepStatus !== 2;
      setSubmissionUnlocked(conversationSubmissionUnlocked);
      setData(responseData);
      setError(false);
      getQuestionCategoriesData();
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getCanvasData = async () => {
    setLoading(true);

    try {
      let Api = new DluchApi('ConversationDataShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.data;
      let canvasData = JSON.parse(responseData.canvasData);
      let flowData = [];

      const keys = Object.keys(canvasData);

      keys.forEach((key, index) => {
        if (canvasData[key]) {
          let json = JSON.parse(canvasData[key].flowData);

          if (json.nodes) {
            for (let node of json.nodes) {
              node.data.locked = true;
            }
          }

          flowData[key] = {
            flowData: json,
            additionalInput: JSON.parse(canvasData[key].additionalInput),
          };
        }
      });

      setCanvasData(flowData);
      setError(false);
    } catch (err) {
      console.dir(err);
    } finally {
      setLoading(false);

      if (reactFlowInstance) {
        setTimeout(
          function () {
            reactFlowInstance.fitView();
          },
          250,
          reactFlowInstance,
        );
      }
    }
  };

  const sortedQuestionResultData = function (categoryData) {
    let responseData = categoryData.sort(function (a, b) {
      let weight = -1;

      if (a.priorityRank > b.priorityRank) {
        weight = 1;
      } else if (a.priorityRank === b.priorityRank) {
        weight = a.categoryName > b.categoryName ? 1 : -1;
      }

      return weight;
    });

    responseData = responseData.reverse();

    responseData = responseData.sort(function (a, b) {
      return a.categoryType - b.categoryType;
    });

    return responseData;
  };

  const getQuestionCategoriesData = async () => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionsCategoriesIndex', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      //let responseData = sortedQuestionResultData(response.data.content.categories);
      let responseData = response.data.content.categories;
      let allCategories = filteredCategories(responseData, 3, false);
      setError(false);
      getCategoryData(allCategories);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getCategoryData = async (questionCategories) => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionResultsShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.results.filter(
        (obj) => obj.isConversationTopic === true,
      );

      let guidedOptionalCategories = filteredCategories(questionCategories, 1);
      guidedOptionalCategories = guidedOptionalCategories.map((obj) => obj.id);

      let selfGuidedCategories = filteredCategories(questionCategories, 2);
      selfGuidedCategories = selfGuidedCategories.map((obj) => obj.id);

      for (let row of questionCategories) {
        row.selfGuided =
          guidedOptionalCategories.includes(row.id) || selfGuidedCategories.includes(row.id);
      }

      for (let row of responseData) {
        row.title = row.optionalCategoryName === null ? row.categoryName : row.optionalCategoryName;
        row.selfGuided = false;
      }

      setCategoriesData(responseData);
      setError(false);
      getCanvasData();
    } catch (err) {
      console.dir(err.message);
      setError(false);
      getCanvasData();
    } finally {
      setLoading(false);
    }
  };

  function isViewable(data, submissionUnlocked) {
    return (data.isUserManager && submissionUnlocked) || !submissionUnlocked;
  }

  function retryFetch() {
    setLoading(true);
    setError(false);
    setData(null);
    getData();
  }

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (currentUser && currentUser.token) {
      retryFetch();
    }

    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  React.useEffect(() => {
    if (canvasData && Object.keys(canvasData).length > 0) {
      setLoading(true);
      for (let categoryIndex of Object.keys(canvasData)) {
        setTimeout(downloadImage, 2000, parseInt(categoryIndex));
      }
      setTimeout(setLoading, 2000, false);
    }
  }, [canvasData]);

  return (
    <>
      <ProgressBar loading={loading} />
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />
      <ErrorRender object={error} />

      <div className="page-content">
        <div className="page-content__header page-content__header--bordered page-content__header--fixed">
          <div className="grid-container">
            <Grid className="grid-padding-x align-middle">
              <Cell small={12} large={2}>
                <Link
                  to="/conversations"
                  className="page-content__button"
                  id="page-navigation-button--go-back"
                >
                  Back
                </Link>
              </Cell>
              <Cell small={12} large={5}>
                {data && isViewable(data, submissionUnlocked) && (
                  <ul className="canvas-categories-list">
                    {Array.apply(null, { length: Object.keys(canvasData).length }).map(
                      (count, categoryIndex) => (
                        <li
                          className="canvas-categories-list__item"
                          key={'question-' + categoryIndex + 1}
                        >
                          <Link
                            to={`/canvases/${data.id}/categories/${categoryIndex + 1}`}
                            state={{ state: { categoryIndex: categoryIndex + 1 } }}
                            className="canvas-categories-list__link"
                            id={`canvas-category-list-link--${categoryIndex + 1}`}
                          >
                            {`Topic ${categoryIndex + 1}`}
                            <span className="canvas-categories-list__icon canvas-categories-list__icon--deselected util--hide-icon">
                              <ChevronUp size={12} />
                            </span>
                          </Link>
                        </li>
                      ),
                    )}
                  </ul>
                )}
              </Cell>
            </Grid>
          </div>
        </div>
      </div>

      <div className="grid-container">
        <Grid className="grid-padding-x">
          <Cell small={12}>
            <div className="page-content page-content--margin">
              {!loading && Object.keys(reactFlowsImages).length > 0 && (
                <>
                  <h1 className="page-content__title">Download Canvas</h1>

                  <PDFDownloadLink
                    document={
                      <MyDocument
                        reactFlowsImages={reactFlowsImages}
                        canvasData={canvasData}
                        conversationData={data}
                      />
                    }
                    fileName="conversation-canvas.pdf"
                    className="basic-form__button"
                    id="page-form-button--submit"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? 'Loading document...' : 'Download PDF'
                    }
                  </PDFDownloadLink>

                  <div>
                    <hr />

                    <PDFViewer style={{ width: '100%', height: '800px' }}>
                      <MyDocument
                        reactFlowsImages={reactFlowsImages}
                        canvasData={canvasData}
                        conversationData={data}
                      />
                    </PDFViewer>
                  </div>
                </>
              )}

              {loading && (
                <>
                  <h1 className="page-content__title">Download Canvas</h1>

                  <div className="page-content__styles">
                    <p>Please wait...</p>
                  </div>
                </>
              )}

              {Array.apply(null, { length: Object.keys(canvasData).length }).map(
                (count, categoryIndex) => (
                  <div key={'reactflow-' + categoryIndex + 1}>
                    <h2 className="page-content__subtitle">Topic {categoryIndex + 1}</h2>
                    <div className={`reactflow-wrap js--reactflow-${categoryIndex + 1}`}>
                      <ReactFlowProvider>
                        <div className="reactflow" ref={reactFlowWrapper}>
                          <Flow
                            nodes={
                              canvasData[categoryIndex + 1] &&
                              canvasData[categoryIndex + 1].flowData
                                ? canvasData[categoryIndex + 1].flowData.nodes
                                : []
                            }
                            edges={
                              canvasData[categoryIndex + 1] &&
                              canvasData[categoryIndex + 1].flowData
                                ? canvasData[categoryIndex + 1].flowData.edges
                                : []
                            }
                            onEdgesChange={onEdgesChange}
                            onInit={onInit}
                            nodeTypes={nodeTypes}
                            snapToGrid={true}
                            snapGrid={snapGrid}
                            defaultViewport={defaultViewport}
                            nodesConnectable={submissionUnlocked}
                            nodesDraggable={submissionUnlocked}
                            elementsSelectable={submissionUnlocked}
                            // edgeTypes={edgeTypes}
                            connectionMode="loose"
                            fitView
                          >
                            <Background color="#aaa" gap={16} />
                          </Flow>
                        </div>
                      </ReactFlowProvider>
                    </div>
                  </div>
                ),
              )}
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  );
}
