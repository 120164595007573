// Backend
import React from 'react';
import axios from 'axios';
import history from '../../../helpers/history';

// Frontend
import { Grid, Cell } from 'react-foundation';
import '../../../stylesheets/components/navigation/FullQuestions.css';

// Functionality
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  Flashable,
  ProgressBar,
  disableFormButton,
  enableFormButton,
  ErrorRender,
  setToken,
} from '../../../constants/SharedComponents';
import useCurrentUser from '../../../hooks/useCurrentUser';

export function ConversationsFullQuestionsEdit() {
  // Params
  const { conversationId, pageNumber } = useParams();
  const navigate = useNavigate();

  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();
  const token = setToken(currentUser);
  const headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };

  // Objects
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [inputs, setInputs] = React.useState({});
  const [data, setData] = React.useState(null);
  const [questionData, setQuestionData] = React.useState(null);
  const [questionOptions, setQuestionOptions] = React.useState([]);
  const [previousPage, setPreviousPage] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(null);
  const [totalPages, setTotalPages] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [lastCategory, setLastCategory] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [formChanged, setFormChanged] = React.useState(false);
  const [pageNavigation, setPageNavigation] = React.useState({});
  const basicFormButtons = React.useRef([]);

  // Functions
  function processQuestionScores(formInputs) {
    let questionScores = [];
    let index = 0;

    // @see https://stackoverflow.com/a/921808
    for (const key in formInputs) {
      if (!inputs.hasOwnProperty(key)) {
        continue;
      }

      // @see https://stackoverflow.com/a/1493071
      let matches = key.split('[');
      let questionId;

      if (matches) {
        questionId = matches[1];
        questionId = questionId.substring(0, questionId.length - 1);
      }

      let obj = inputs[key];

      for (const prop in obj) {
        if (!obj.hasOwnProperty(prop)) {
          continue;
        }

        questionScores[index] = {
          conversationId: conversationId,
          questionId: questionId,
          score: obj[prop],
        };
      }

      index++;
    }

    return questionScores;
  }

  function createLocalStorage(formInputs) {
    let questionScores = processQuestionScores(formInputs);

    let questionScoresStorage = localStorage.getItem('questionScores');
    questionScoresStorage = JSON.parse(questionScoresStorage);
    questionScoresStorage['questionScores'][currentPage] = questionScores;

    let savedQuestionScores = { questionScores: questionScoresStorage['questionScores'] };
    localStorage.setItem('questionScores', JSON.stringify(savedQuestionScores));

    setInputs({});
  }

  function createSubmission(formInputs) {
    createLocalStorage(formInputs);

    let Api = new DluchApi('QuestionsNew');

    setInputs({});

    if (!lastCategory) {
      setFormChanged(false);

      let redirectTo =
        '/conversations/' + conversationId + '/full-questions/' + (currentPage + 1) + '/edit';

      setPageNavigation({
        to: redirectTo,
        options: { state: { currentPage: currentPage + 1 } },
      });
    } else {
      let redirectTo = '/conversations/' + conversationId + '/categories/edit';
      let message = 'The full survey is now successfully complete.';
      let requestPayload;
      let questionScoresData = JSON.parse(localStorage.getItem('questionScores'));

      if (data && data.id) {
        requestPayload = {
          questionScores: questionScoresData['questionScores'].flat(),
        };
      }

      axios
        .post(Api.ApiRequestUrl(), requestPayload, headerParams)
        .then(() => {
          setFormChanged(false);
          localStorage.removeItem('questionScores');

          setPageNavigation({
            to: redirectTo,
            options: { state: Flashable(message, 'notice') },
          });
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            setFlashMessageObject(Flashable(error.response.data.errorDesc, 'alert', false));
          } else {
            setFlashMessageObject(Flashable(error.message, 'alert', false));
          }
          enableFormButton(basicFormButtons);
          setLoading(false);
        });
    }
  }

  function retryFetch() {
    setLoading(true);
    setError(false);
    setData(null);
    setCurrentPage(parseInt(pageNumber));

    if (localStorage.getItem('questionScores') === null) {
      const redirectTo = '/conversations/' + conversationId + '/team-member-prep/new';
      navigate(redirectTo);
    } else {
      getData();
    }
  }

  const getData = async () => {
    try {
      let Api = new DluchApi('ConversationsShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.conversation;

      setData(responseData);
      setError(false);
      setQuestionData(null);
      getQuestionData();
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const paginate = function (arr, size) {
    return arr.reduce((acc, val, i) => {
      let idx = Math.floor(i / size);
      let page = acc[idx] || (acc[idx] = []);
      page.push(val);

      return acc;
    }, []);
  };

  const getQuestionData = async () => {
    const intPageNumber = parseInt(pageNumber);
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionsIndex', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.sortedQuestions;
      responseData.shift();

      responseData = paginate(responseData, 7);
      setTotalPages(responseData);
      console.dir(responseData);
      setQuestionData(responseData[intPageNumber - 1]);

      setLastCategory(responseData.length === intPageNumber);
      setPreviousPage(intPageNumber - 1 >= 1 ? intPageNumber - 1 : null);

      setFormChanged(intPageNumber - 1 >= 1);

      setError(false);
      setQuestionOptions(JSON.parse(responseData[intPageNumber - 1][0].questionOptions));

      if (localStorage.getItem('questionScores') !== null) {
        let questionScoresData = JSON.parse(localStorage.getItem('questionScores')).questionScores;
        const previouslyAnswered = questionScoresData[intPageNumber];

        if (previouslyAnswered) {
          let defaultInputs = {};

          for (let questionScore of previouslyAnswered) {
            defaultInputs['question_score[' + questionScore.questionId + '][option_score]'] =
              questionScore.score;
          }

          setInputs(defaultInputs);
        }
      }
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (currentUser && currentUser.token) {
      retryFetch();
    }

    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  React.useEffect(() => {
    let unblock;
    if (formChanged) {
      unblock = history.block((tx) => {
        let url = tx.location.pathname;
        console.info(`Attempted navigation to ${url}`);

        if (
          window.confirm(
            `You have unsaved changes. If you navigate away from this page, these changes will be lost.`,
          )
        ) {
          unblock();
          tx.retry();
        }
      });
    }

    if (!formChanged && pageNavigation) {
      navigate(pageNavigation.to, pageNavigation.options);
    }

    return () => {
      if (typeof unblock === 'function') {
        unblock();
      }
    };
  }, [formChanged, pageNavigation]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    disableFormButton(basicFormButtons);
    createSubmission(inputs);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    setFormChanged(true);
  };

  const previousPageNavigation = (event) => {
    event.preventDefault();
    const pageNumber = event.target.dataset.pageNumber;
    createLocalStorage(inputs);
    setFormChanged(false);
    setPageNavigation({
      to: event.target.getAttribute('href'),
      options: { state: { currentPage: pageNumber } },
    });
  };

  const pageNavigationClassName = (pageNumber) => {
    let navigationClassName = '';

    if (pageNumber === currentPage) {
      navigationClassName = 'current';
    } else if (pageNumber > currentPage) {
      navigationClassName = 'next';
    } else if (pageNumber < currentPage) {
      navigationClassName = 'previous';
    }

    return navigationClassName;
  };

  return (
    <>
      <ProgressBar loading={loading} />
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />
      <ErrorRender object={error} />

      {data && questionData && (
        <>
          <div className="grid-container">
            <Grid className="grid-padding-x align-justify">
              <Cell small={12}>
                <div className="page-content">
                  <div className="page-content__header">
                    {previousPage && (
                      <Link
                        to={`/conversations/${conversationId}/full-questions/${previousPage}/edit`}
                        state={{ state: { currentPage: previousPage } }}
                        className="page-content__button"
                        onClick={previousPageNavigation}
                        data-page-number={previousPage}
                        id="page-navigation-button--go-back"
                      >
                        Go back
                      </Link>
                    )}
                  </div>
                </div>

                {totalPages && currentPage && (
                  <>
                    <ul className="question-response-pagination-list">
                      {totalPages.map((page, pageIndex) => (
                        <li
                          className="question-response-pagination-list__item"
                          key={'question-' + page[0].id}
                        >
                          {pageIndex + 1 === currentPage ? (
                            <span
                              className={`question-response-pagination-list__faux-link question-response-pagination-list__faux-link--${pageNavigationClassName(
                                pageIndex + 1,
                              )}`}
                            >
                              {pageIndex + 1}
                            </span>
                          ) : (
                            <Link
                              to={`/conversations/${conversationId}/full-questions/${
                                pageIndex + 1
                              }/edit`}
                              state={{ state: { currentPage: pageIndex + 1 } }}
                              className={`question-response-pagination-list__link question-response-pagination-list__link--${pageNavigationClassName(
                                pageIndex + 1,
                              )}`}
                              onClick={previousPageNavigation}
                              data-page-number={pageIndex + 1}
                              id={`page-navigation-button--page-${pageIndex + 1}`}
                            >
                              {pageIndex + 1}
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </>
                )}

                {!data.isUserManager && (
                  <>
                    <Grid className="grid-padding-x align-justify">
                      <Cell small={7}></Cell>
                      {questionOptions.map((questionOption, questionOptionIndex) => {
                        return (
                          <Cell
                            small={1}
                            className="text-center basic-form__faux-label"
                            key={questionOption.OptionScore}
                          >
                            <b>{questionOption.OptionText}</b>
                          </Cell>
                        );
                      })}
                    </Grid>
                    <form className="basic-form" onSubmit={handleSubmit}>
                      {questionData.map((question, questionIndex) => {
                        return (
                          <div
                            className="basic-form__field basic-form__field--row"
                            key={`question-${question.id}`}
                          >
                            <Grid className="grid-padding-x align-justify">
                              <Cell small={7}>
                                <p className="basic-form__faux-label">{question.questionText}</p>
                              </Cell>

                              {JSON.parse(question.questionOptions).map(
                                (questionOption, optionIndex) => {
                                  return (
                                    <Cell
                                      small={1}
                                      className="text-center"
                                      key={
                                        'question-' +
                                        question.id +
                                        '-response-' +
                                        questionOption.OptionScore
                                      }
                                    >
                                      <input
                                        type="radio"
                                        name={'question_score[' + question.id + '][option_score]'}
                                        value={questionOption.OptionScore}
                                        onChange={handleChange}
                                        defaultChecked={
                                          inputs[
                                            'question_score[' + question.id + '][option_score]'
                                          ] === questionOption.OptionScore.toString()
                                        }
                                        id={
                                          'util--question-' +
                                          questionIndex +
                                          '-response-' +
                                          optionIndex
                                        }
                                        required={true}
                                        className="basic-form__radio-button"
                                      />

                                      <label
                                        className="basic-form__label--sr-only"
                                        htmlFor={
                                          'util--question-' +
                                          questionIndex +
                                          '-response-' +
                                          optionIndex
                                        }
                                      >
                                        {questionOption.OptionText}
                                      </label>
                                    </Cell>
                                  );
                                },
                              )}
                            </Grid>
                          </div>
                        );
                      })}

                      <div className="basic-form__actions">
                        {previousPage && (
                          <Link
                            to={`/conversations/${conversationId}/full-questions/${previousPage}/edit`}
                            state={{ state: { currentPage: previousPage } }}
                            className="basic-form__button basic-form__button--multiple basic-form__button--secondary"
                            onClick={previousPageNavigation}
                            data-page-number={previousPage}
                            id="page-navigation-button--back"
                          >
                            Back
                          </Link>
                        )}

                        <input
                          value={lastCategory === false ? 'Next' : 'Submit'}
                          type="submit"
                          className="basic-form__button"
                          ref={(ref) => (basicFormButtons.current[0] = ref)}
                          id="page-form-button--button"
                        />
                      </div>
                    </form>
                  </>
                )}
              </Cell>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}
