// Backend
import React from 'react';

// Frontend
import { Grid, Cell, Button } from 'react-foundation';
import { ArrowRightCircle } from 'react-bootstrap-icons';

// Functionality
import { Link } from 'react-router-dom';
import format from 'date-fns/format';

const FilterComponent = ({ filterText, filterStatus, onFilterText, onFilterStatus, onClear }) => (
  <>
    <form className="conversations-filter-form">
      <Grid className="grid-padding-x align-justify">
        <Cell small={12} large={6}>
          <input
            type="search"
            value={filterText}
            onChange={onFilterText}
            required={true}
            placeholder="Filter..."
            className="conversations-filter-form__input"
          />
        </Cell>

        <Cell small={4}>
          <select
            value={filterStatus}
            onChange={onFilterStatus}
            className="conversations-filter-form__input"
          >
            <option value="">Filter by Status</option>
            <option value="0">Pending</option>
            <option value="1">In Progress</option>
            <option value="2">Completed</option>
          </select>
        </Cell>

        <Cell small={2}>
          {(filterStatus || filterText) && (
            <Button onClick={onClear} className="conversations-filter-form__button">
              Clear filter
            </Button>
          )}
        </Cell>
      </Grid>
    </form>
  </>
);

function StatusColumn({ props }) {
  let statusString,
    className,
    button,
    buttonString = 'View';

  switch (props.status) {
    case 0:
      statusString = 'Not Started';
      className = 'pending';
      break;
    case 1:
      statusString = 'In Progress';
      className = 'in-progress';
      break;
    case 2:
      statusString = 'Complete';
      className = 'complete';
      break;
    case 3:
      statusString = 'Deleted';
      break;
    default:
      statusString = 'unknown';
  }

  if (!props.isCanvasData) {
    buttonString = 'Start';
  } else if (props.isCanvasData && props.status < 2) {
    buttonString = 'Continue';
  }

  if (props.isUserManager === true || props.status === 2) {
    button = (
      <Link to={`/canvases/${props.id}/categories/new`} className="data-table__faux-button">
        {buttonString}
        <ArrowRightCircle size={16} className="data-table__icon" />
      </Link>
    );
  }

  return (
    <span className={'data-table__icon-link data-table__icon-link--' + className}>
      <span className="data-table__status-string">{statusString}</span>
      {button}
    </span>
  );
}

function UpdatedAtColumn({ props }) {
  let date = new Date(props.updatedAt);
  let dateTime = format(date, 'dd/MM/yy');

  return dateTime;
}

function CreatedAtColumn({ props }) {
  let date = new Date(props.createdAt);
  let dateTime = format(date, 'dd/MM/yy');

  return dateTime;
}

function ManagerColumn({ props }) {
  const name = props.isOrganiserManager === true ? props.organiserName : props.attendeeName;
  const creator = props.isOrganiserManager === true ? '*' : '';

  return name + creator;
}

function TeamMemberColumn({ props }) {
  const name = props.isOrganiserManager !== true ? props.organiserName : props.attendeeName;
  const creator = props.isOrganiserManager !== true ? '*' : '';

  return name + creator;
}

function EmployeePrepColumn(props) {
  let statusString, buttonString, conversationLink, className, icon;

  switch (props.employeePrepStatus) {
    case 0:
      buttonString = 'Start';
      statusString = 'Not Started';
      className = 'pending';
      break;
    case 1:
      buttonString = 'Continue';
      statusString = 'In Progress';
      className = 'in-progress';
      break;
    case 2:
      buttonString = 'View';
      statusString = 'Complete';
      className = 'complete';
      break;
    default:
      statusString = 'unknown';
  }

  switch (props.employeePrepStatus) {
    case 0:
      conversationLink = !props.isUserManager
        ? '/conversations/' + props.id + '/team-member-prep/homes'
        : '';
      break;
    case 1:
      conversationLink = !props.isUserManager
        ? '/conversations/' + props.id + '/categories/edit'
        : '';
      break;
    case 2:
      conversationLink = !props.isUserManager
        ? '/conversations/' + props.id + '/categories/edit'
        : '/conversations/' + props.id + '/managers';
      break;
    default:
      conversationLink = '';
  }

  let button = (
    <span className={`data-table__faux-button data-table__faux-button--${className}`}>
      {buttonString}
      <ArrowRightCircle size={16} className="data-table__icon" />
    </span>
  );

  if (conversationLink) {
    icon = (
      <Link
        to={conversationLink}
        className={'data-table__icon-link data-table__icon-link--' + className}
      >
        <span className="data-table__status-string">{statusString}</span>
        {button}
      </Link>
    );
  } else {
    icon = (
      <span className={'data-table__icon-link data-table__icon-link--' + className}>
        <span className="data-table__status-string">{statusString}</span>
      </span>
    );
  }

  return icon;
}

const dataTableColumns = [
  {
    name: 'Manager',
    selector: (row) => (row.isOrganiserManager === true ? row.organiserName : row.attendeeName),
    cell: (props) => ManagerColumn({ props }),
    sortable: true,
    fixed: true,
    reorder: false,
    width: '200px',
  },
  {
    name: 'Team member',
    selector: (row) => (row.isOrganiserManager !== true ? row.organiserName : row.attendeeName),
    cell: (props) => TeamMemberColumn({ props }),
    sortable: true,
    fixed: true,
    reorder: false,
    width: '200px',
  },
  {
    name: 'Created',
    selector: (row) => row.createdAt,
    cell: (props) => CreatedAtColumn({ props }),
    sortable: true,
    reorder: false,
    fixed: true,
    width: '110px',
  },
  {
    name: 'Updated',
    selector: (row) => row.updatedAt,
    cell: (props) => UpdatedAtColumn({ props }),
    sortable: true,
    reorder: false,
    fixed: true,
    width: '140px',
  },
  {
    name: 'Preparation',
    selector: (row) => row.employeePrepStatus,
    cell: (props) => EmployeePrepColumn(props),
    sortable: true,
    reorder: false,
    fixed: true,
  },
  {
    name: 'Conversation',
    selector: (row) => row.status,
    cell: (props) => StatusColumn({ props }),
    sortable: true,
    reorder: false,
    fixed: true,
  },
];

export { FilterComponent, dataTableColumns };
